import styles from "./guidelinerender.module.css";
import {createMarkup} from "../../utils";
import React from "react";

export const GuidelineRender = ({children, html, margin = 32}: {html: string, margin?: number, children?: any}): JSX.Element => {
    return (
        <div className={styles.content} style={{marginBottom: margin}}  >
            <div dangerouslySetInnerHTML={createMarkup(html)}/>
            {children}
        </div>
    )
}