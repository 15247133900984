import { httpClient } from './httpClient';

let apiBaseUrl = process.env.REACT_APP_API;

export const getTaxonomy = async () => {
	return httpClient(`${apiBaseUrl}/taxonomy`);
};

export const getArticle = async uuidCategory => {
	return httpClient(`${apiBaseUrl}/articles/${uuidCategory}`);
};

export const searchArticle = async (query: string) => {
	return httpClient(`${apiBaseUrl}/articles/search/${query}`);
};

export const feedbackArticle = async (customer_uuid, article_uuid, article_rate, comments) => {
	let url = `${apiBaseUrl}/articles/feedback`;
	let data = {
		customer_uuid,
		article_uuid,
		article_rate
	};

	if (comments) {
		data['article_comments'] = comments;
	}

	return httpClient(url, { body: { data } });
};

export const getSubcategory = async (category_uuid: string, signal) => {
	return httpClient(`${apiBaseUrl}/taxonomy/${category_uuid}/categories`, { signal });
};

export const getSubcategoryBySlug = async (category_slug: string, signal) => {
	return httpClient(`${apiBaseUrl}/categories/search/${category_slug}`, { signal });
};
