import { ga } from 'react-ga';

declare var newrelic;

export function handleHttpErrors(error): void {
    if (error?.stack && error?.message) {
        newrelic?.noticeError(error.stack);
        ga('send', 'exception', {
            exDescription: error.stack,
            exFatal: false
        });
    }

}