import React, { useEffect } from 'react';
import styles from './ticketpage.module.css';
import { event } from 'react-ga';
import {useDispatch, useSelector} from 'react-redux';
import { selectArticle } from '../../store/articleSlice';
import { IDataUser, selectUser } from '../../store/userSlice';
import {useHistory} from 'react-router-dom';
import useTitle from '../../hooks/useTitle';
import {fetchCreateTicket, IRequestTicket, selectTicketCreated} from '../../store/ticketSlice';
import { useForm } from 'react-hook-form';
import { buildBodyTicket } from '../../utils';
import {ErrorForm, HeaderBack, InputWhitThumbnail} from 'src/components';
import {EVENT} from "../../config/analytics";
import {PageContainer} from "../../components/PageContainer/PageContainer";
import {AppDispatch} from "../../store/store";
import {removeDataInfoFromFileBase64} from "../../utils/files";
import {handleHttpErrors} from "../../utils/handleHttpErrors";
import {trackPage} from "../../utils/track";

const TicketPage = () => {
	useTitle('Ticket help center - albo');
	const { item: article } = useSelector(selectArticle);
	const user: IDataUser = useSelector(selectUser);
	const ticketCreated = useSelector(selectTicketCreated);
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();

	const {
		register,
		formState: { errors, isValid },
		trigger,
		handleSubmit,
		control,
		setValue,
		watch
	} = useForm({ mode: 'onChange' });

	const filesWatch = watch('files');

	useEffect(() => {
		trackPage({ page: '/ticket-generic'});
	}, []);

	useEffect(() => {
		event({category: EVENT.navigation.category, action: EVENT.navigation.actions.razon_soporte, label:  article.name});
	}, [article])

	const handleSubmitTicket = async ({ title, description,files }) => {
		const data: IRequestTicket = buildBodyTicket({
			customer: user,
			article,
			files: removeDataInfoFromFileBase64(files),
			extra: { title, description }
		});
		dispatch(fetchCreateTicket({body: data}))
			.unwrap()
			.then(response => {
				event({category: EVENT.ticket.category, action: EVENT.ticket.actions.razon_soporte_created, label: response.response.supportName})
				history.push('/ticket-success');
			})
			.catch((error) => {
				handleHttpErrors(error);
				history.push('/ticket-error');
			});
	};


	const showErrors = (): void => {
		if (!isValid) {
			trigger();
		}
	};

	const renderContentPage = () => (
		<>
			<HeaderBack title="Contáctanos" />
			<form onSubmit={handleSubmit(handleSubmitTicket)} className={styles.form}>
				<label htmlFor="title">
					<p>
						<b>Título</b>
					</p>
				</label>
				<input
					{...register('title', {
						required: { message: 'El campo título es requerido', value: true },
						maxLength: { message: 'Máximo 60 caracteres', value: 60 }
					})}
					type="text"
					id="title"
					placeholder="De qué tema es tu problema"
					autoComplete="off"
					className={errors?.title?.type === 'required' ? styles.required : ''}
				/>
				<ErrorForm errors={errors} name="title" />
				<label htmlFor="detail">
					<p>
						<b>Descripción</b>
					</p>
				</label>
				<textarea
					{...register('description', {
						required: { message: 'El detalle es requerido', value: true },
						maxLength: { message: 'Máximo 500 caracteres', value: 500 }
					})}
					id="detail"
					placeholder="Cuéntanos todos los detalles"
					autoComplete="off"
					className={errors?.description?.type === 'required' ? styles.required : ''}
				/>
				<ErrorForm errors={errors} name="description" />
				<InputWhitThumbnail control={control} name="files" files={filesWatch} setValue={setValue}/>
				<div className="fixed-bottom m-16">
					<button
						onClick={showErrors}
						disabled={ticketCreated.status === 'pending'}
						className={`btn-primary ${isValid ? '' : styles.disabled}`}
						type="submit"
					>
						<b>Enviar</b>
					</button>
				</div>
			</form>
		</>
	);

	return (
		<>
			<PageContainer
			showLoader={ticketCreated.status === 'pending'}
			height={100}
			showChildren={ticketCreated.status === 'initial'}
			>
				{renderContentPage()}
			</PageContainer>
		</>
	);
};

export default TicketPage;
