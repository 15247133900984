import {rest} from "msw";

const service = process.env.REACT_APP_API_URL_TICKETS;

export const mockCreateTicket = rest.post(`${service}/tickets`, (req, res, ctx) => {
    return res(
        ctx.status(200),
        ctx.json({
            "data": {
                "ticket_uuid": "3e6817c5-f95b-466a-8c60-89f65bb8f9ce"
            }
        })
    )
})