import {fetchRefreshToken} from '../store/userSlice';
import {AuthError} from "../utils/customErrors/AuthError";
import {ServiceError} from "../utils/customErrors/ServiceError";

let cacheStore;

interface ICustomRequest extends RequestInit {
    body?: any;
}

async function httpClientAuth(
    endpoint,
    {body, ...customConfig}: ICustomRequest = {body: null, headers: null}
): Promise<any> {
    if (!cacheStore) {
        cacheStore = await import('../store/store');
    }
    const token = cacheStore.default.getState()?.user?.auth?.token;

    if (!token) {
        throw new AuthError('Not logged in or expired')
    }

    return httpClient(endpoint, {body, ...customConfig}, token);
}

function httpClient(
    endpoint,
    {body, ...customConfig}: ICustomRequest = {body: null, headers: null},
    token = null
): Promise<any> {
    const customHeaders: HeadersInit = {'content-type': 'application/json'};
    if (token) {
        customHeaders.Authorization = `Bearer ${token}`;
    }
    const config: RequestInit = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...customHeaders,
            ...customConfig.headers
        }
    };
    if (body) {
        config.body = JSON.stringify(body);
    }


    return fetch(endpoint, config).then(async response => {
        // TODO: pantalla cuando refresh token arroje 401
        if (response.status === 401 && endpoint.search('refresh') === -1) {
            if (!cacheStore) {
                cacheStore = await import('../store/store');
            }
            const refreshToken = cacheStore.default.getState().user.auth.refreshToken;
            return cacheStore.default.dispatch(fetchRefreshToken({token: refreshToken}));
        }
        if (response.ok) {
            return await response.json();
        } else {
            const error = await response.text();
            throwHttpError(response.status, response.statusText, error);
        }
    });
}

export {httpClient, httpClientAuth};

function throwHttpError(status: number, statusCode: string, error: string) {
    let message: string;
    if (error) {
        const jsonError = JSON.parse(error);
        if (jsonError?.message) {
            message = `status: ${status}, status code: ${statusCode}, message: ${jsonError?.message}`;
        } else if (error){
            message = `Status: ${statusCode}, Error: ${error}`;
        }
    } else {
        message = 'Error desconocido';
    }
    throw new ServiceError(message);
}
