import React from 'react';
import ReactDOM from 'react-dom';
import './assets/albo-icons-fonts.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import AppRoutes from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import {worker} from "./mocks/browser";


if (process.env?.REACT_APP_MOCKS_ENABLE && JSON.parse(process.env.REACT_APP_MOCKS_ENABLE)) {
	const { worker } = require('./mocks/browser');
	worker.start();
}


ReactDOM.render(
	<Provider store={store}>
		<AppRoutes />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (process.env.NODE_ENV !== 'development') {
	serviceWorker.unregister();
}
