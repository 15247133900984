import { createMarkup, dateWithFormat, timeWithFormat } from '../../../utils';
import styles from '../ticketdetail.module.css';
import { FileType } from './FileType';

const Messages = ({ messages }) => {
	return (
		<div>
			{messages.items.map(commentsOfTheDay => {
				return (
					<div key={commentsOfTheDay.date}>
						<div className="text-center">{dateWithFormat(commentsOfTheDay.date)}</div>
						{commentsOfTheDay.comments.map((message, index) => {
							return (
								<div key={message.cdate + index}>
									{message.type_comment === 'agent' && <span className={styles.albo}>albo</span>}
									<div
										className={
											message.type_comment === 'customer' ? `m-y-16 ${styles.customer}` : `m-b-16 ${styles.agent}`
										}
									>
										<div className={styles.content} dangerouslySetInnerHTML={createMarkup(message.conversation)} />
										<span className={styles.time}>{timeWithFormat(message.cdate)}</span>
									</div>
									<div className={message.type_comment === 'customer' ? styles.containerImgCustomer : ''}>
										{message?.signedFiles?.map((url: string) => {
											console.log({ message });
											return <FileType url={url} />;
										})}
									</div>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};

export default Messages;
