import styles from '../ticketdetail.module.css';
import iconDoc from '../../../assets/images/document.png';

function download(url) {
	const link = document.createElement('a');
	link.href = url;
	link.target = '_blank';
	link.click();
}

export const FileType = ({ url, type, className }: { url: string; type?: string; className?: string }) => {
	console.log({ url, type });
	const styleFile = className ?? styles.thumbnail;
	if (type) {
		const typeFile = type.split('/')[0];
		if (typeFile === 'image') {
			return <img key={url} className={styleFile} src={url} alt="file" onClick={() => download(url)} />;
		}
		return (
			<img
				key={url}
				className={styleFile}
				style={{ objectFit: 'contain' }}
				src={iconDoc}
				alt="file"
				onClick={() => download(url)}
			/>
		);
	}

	const filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0];
	const extension = filename.split('.').pop();

	// TODO: se debe de guardar el content-type
	switch (extension) {
		case 'png':
		case 'jpg':
		case 'jpeg':
		case 'gif':
		case 'bmp':
			return <img key={url} className={styleFile} src={url} alt="file" onClick={() => download(url)} />;
		default:
			return (
				<img
					key={url}
					className={styleFile}
					style={{ objectFit: 'contain' }}
					src={iconDoc}
					alt="file"
					onClick={() => download(url)}
				/>
			);
	}
};
