import React, {useEffect} from 'react';
import styles from './onboarding.module.css';
import {useParams} from 'react-router-dom';
import useTitle from '../../hooks/useTitle';
import {useDispatch, useSelector} from 'react-redux';
import {event} from 'react-ga';
import {ListSubcategory, Loader, ServerError} from 'src/components';
import {EVENT} from "../../config/analytics";
import {AppDispatch} from "../../store/store";
import {fetchGetSubcategoryBySlug, selectSubcategories} from "../../store/categorySlice";
import {trackPage} from "../../utils/track";

const SubCategorySlugPage = () => {
    useTitle('Categoría - albo');
    const dispatch = useDispatch<AppDispatch>();
    const categories = useSelector(selectSubcategories);
    let {category_slug} = useParams<{ category_slug: string }>();

    useEffect(() => {
        const promise = dispatch(fetchGetSubcategoryBySlug(category_slug));
        trackPage({page: `/category/direct/${category_slug}`});
        return () => {
            promise.abort();
        }
    }, []);

    useEffect(() => {

        if (categories.items[0]) {
            event({
                category: EVENT.navigation.category,
                action: EVENT.navigation.actions.category_direct,
                label: categories.items[0].name
            })
        }
    }, [categories])

    return (
        <>
            {categories.status === 'success' && categories.items.length > 0 && (
                <>
                    <div className={styles.title}>
                        <div className={styles.titleIcon}>
                            <i className={categories.items[0].icon}/>
                            <h3>{categories.items[0].name}</h3>
                        </div>
                    </div>
                    <ListSubcategory subcategories={categories.items}/>
                </>
            )}
            {categories.status === 'pending' && <Loader height={70}/>}
            {categories?.status === 'error' && <ServerError/>}
        </>
    );
};

export default SubCategorySlugPage;
