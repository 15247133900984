import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRefreshToken } from './userSlice';
import { getSubcategory, getSubcategoryBySlug } from '../http/articleService';
import { getTaxonomy } from '../http/articleService';

export const fetchGetTaxonomy = createAsyncThunk('comments/fetchGetTaxonomy', async (_, thunkAPI) => {
	let response = await getTaxonomy();
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getTaxonomy();
	}
	return response.data;
});

export const fetchGetSubcategoryBySlug = createAsyncThunk(
	'comments/fetchGetSubcategorySlug',
	async (category_slug: string, { getState, signal }) => {
		let response = await getSubcategoryBySlug(category_slug, signal);
		if (response.type === fetchRefreshToken.fulfilled.type) {
			response = await getSubcategoryBySlug(category_slug, signal);
		}
		return response.data;
	}
);

export const fetchGetSubcategory = createAsyncThunk(
	'comments/fetchGetSubcategory',
	async (category_uuid: string, { getState, signal }) => {
		// @ts-ignore
		const category = getState().category.allSubcategories.find(category => category.category_uuid === category_uuid);
		if (category) {
			return category;
		} else {
			let response = await getSubcategory(category_uuid, signal);
			if (response.type === fetchRefreshToken.fulfilled.type) {
				response = await getSubcategory(category_uuid, signal);
			}
			return response.data;
		}
	}
);


const initialCategory: ICategoryState = {
	taxonomy: {
		status: 'initial',
		items: []
	},
	selectedCategory: {
		active: null,
		icon: null,
		order: null,
		status: null,
		cdate: null,
		name: null,
		path: null,
		uuid: null,
		_id: null
	},
	subcategories: {
		status: 'initial',
		items: []
	},
	allSubcategories: []
};

const article: ICategoryState = JSON.parse(sessionStorage.getItem('store'))?.category ?? initialCategory;

export interface ICategoryState {
	taxonomy: {
		status: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
		items: Array<ITaxonomy>;
	};
	selectedCategory: ITaxonomy;
	subcategories: {
		status: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
		items: Array<ISubcategories>;
	};
	allSubcategories: Array<{
		category_uuid: string;
		items: Array<ISubcategories>;
	}>;
}

export interface ITaxonomy {
	active: boolean;
	cdate: string;
	icon: string;
	name: string;
	order: number;
	path: string;
	status: string;
	uuid: string;
	_id: string;
}

export interface ISubcategories {
	_id: string;
	uuid: string;
	uuidTaxonomy: string;
	path: string;
	name: string;
	active: boolean;
	order: number;
	type: string;
	cDate: string;
	articles?: Array<{ name: string; uuid: string; _id: string }>;
	directUuidArticle?: string;
	icon?: string;
}


export const articleSlice = createSlice({
	name: 'category',
	initialState: article,
	reducers: {
		clearSubcategories: state => {
			state.subcategories.status = 'initial';
			state.subcategories.items = [];
		},
		setCategorySelected: (state, param: PayloadAction<ITaxonomy>) => {
			state.selectedCategory = param.payload;
		},
	},
	extraReducers: builder => {
		//   -------------start getSubcategory---------------
		builder.addCase(
			fetchGetSubcategory.fulfilled,
			(
				state,
				action: PayloadAction<Array<ISubcategories> | { category_uuid: string; items: Array<ISubcategories> }>
			) => {
				state.subcategories.status = 'success';
				if (Array.isArray(action.payload)) {
					state.subcategories.items = action.payload;
					state.allSubcategories = [
						...state.allSubcategories,
						{ items: action.payload, category_uuid: action.payload[0].uuidTaxonomy }
					];
				} else {
					state.subcategories.items = action.payload.items;
				}
			}
		);
		builder.addCase(fetchGetSubcategory.pending, (state, action: PayloadAction<Array<ISubcategories>>) => {
			state.subcategories.status = 'pending';
			state.subcategories.items = [];
		});
		builder.addCase(fetchGetSubcategory.rejected, (state, action) => {
			state.subcategories.status = 'error';
		});
		builder.addCase(fetchGetSubcategoryBySlug.pending, (state, action) => {
			state.subcategories.status = 'pending';
		});
		builder.addCase(fetchGetSubcategoryBySlug.fulfilled, (state, action) => {
			state.subcategories.status = 'success';
			state.subcategories.items = action.payload;
		});
		builder.addCase(fetchGetSubcategoryBySlug.rejected, (state, action) => {
			state.subcategories.status = 'error';
		});
		//   -------------end getSubcategory---------------


		//   -------------start taxonomy---------------
		builder.addCase(fetchGetTaxonomy.fulfilled, (state, action: PayloadAction<Array<ITaxonomy>>) => {
			state.taxonomy.status = 'success';
			state.taxonomy.items = action.payload;
		});
		builder.addCase(fetchGetTaxonomy.pending, (state, action) => {
			state.taxonomy.status = 'pending';
			state.taxonomy.items = action.payload;
			state.taxonomy.messageError = null;
		});
		builder.addCase(fetchGetTaxonomy.rejected, (state, action) => {
			console.error('action: ', action);
			state.taxonomy.status = 'error';
			// TODO: manejar mejor errores
			state.taxonomy.messageError = action?.error?.message;
		});
		//   -------------end taxonomy---------------
	}
});

// Action creators are generated for each case reducer function
export const {setCategorySelected, clearSubcategories} = articleSlice.actions;

export const selectSubcategories = (state: { category: ICategoryState }) => state.category.subcategories;
export const selectAllSubcategories = (state: { category: ICategoryState }) => state.category.allSubcategories;
export const selectTaxonomy = (state: { category: ICategoryState }) => state.category.taxonomy;
export const selectSelectedCategory = (state: { category: ICategoryState }) => state.category.selectedCategory;

export default articleSlice.reducer;
