import { useForm } from 'react-hook-form';
import InputWhitThumbnail from '../../InputWhitThumbnail/InputWhitThumbnail';
import styles from './dinamicform.module.css';
import ErrorForm from '../ErrorForm/ErrorForm';
import { Label } from '../index';
import Select from '../Select/Select';
import { useSelector } from 'react-redux';
import { selectTicketCreated } from '../../../store/ticketSlice';
import { Redirect } from 'react-router-dom';

const DynamicForm = ({ configForm, submit }) => {
	const ticketCreated = useSelector(selectTicketCreated);
	const {
		formState: { errors },
		handleSubmit,
		...formHook
	} = useForm();
	return (
		<form onSubmit={handleSubmit(submit)} className={styles.form}>
			<p>
				<b>{configForm.title}</b>
			</p>

			{renderFields({ fields: configForm.fields, formHook: { ...formHook, errors } })}
			<button disabled={ticketCreated.status === 'pending'} type="submit" className="btn-primary m-y-16">
				{ticketCreated.status === 'initial' && (configForm.titleButton ? configForm.titleButton : 'Enviar')}
				{ticketCreated.status === 'pending' && 'Enviando...'}
				{ticketCreated.status === 'error' && <Redirect to="/ticket-error" />}
				{ticketCreated.status === 'success' && <Redirect to="/ticket-success" />}
			</button>
		</form>
	);
};

function renderFields({ fields, formHook }) {
	const { register, watch, setValue, control, errors } = formHook;

	return fields.map(field => {
		let { type, title, name, validations, options, placeholder, multiple } = field;

		let output;

		switch (type) {
			case 'number':
			case 'datetime-local':
			case 'text':
				output = (
					<input
						type={type}
						name={name}
						{...register(name, validations)}
						placeholder={placeholder}
						className={errors[name]?.type === 'required' ? styles.required : ''}
					/>
				);
				break;
			case 'file':
				const watchFiles = watch(name);
				output = (
					<>
						<InputWhitThumbnail
							files={watchFiles}
							setValue={setValue}
							control={control}
							name={name}
							multiple={multiple}
							validations={validations}
						/>
						<hr className={'m-b-8'} />
					</>
				);
				break;
			case 'textarea':
				output = (
					<textarea
						name={name}
						{...register(name, validations)}
						placeholder={placeholder}
						rows={4}
						className={errors[name]?.type === 'required' ? styles.required : ''}
					/>
				);

				break;
			case 'select':
				output = <Select options={options} name={name} validations={validations} register={register} />;
				break;
			default:
				output = <span>Invalid Field</span>;
				break;
		}

		return (
			<div key={name}>
				{title && <Label title={title} name={name} required={validations.required} />}
				{output}
				<ErrorForm errors={errors} name={name} />
			</div>
		);
	});
}

export default DynamicForm;
