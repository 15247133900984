import React from 'react';
import styles from './card.module.css';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {clearSubcategories, setCategorySelected} from "../../store/categorySlice";

const Card = ({ category }): JSX.Element => {
	const history = useHistory();
	const dispatch = useDispatch();

	const handleClick = async () => {
		await dispatch(clearSubcategories());
		await dispatch(setCategorySelected(category));
		history.push(`/category/${category.uuid}`);
	};

	return (
		<button className={styles.container} onClick={handleClick}>
			<i className={category.icon} />
			<p>{category.name}</p>
		</button>
	);
};

export default Card;
