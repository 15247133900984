import React, { useEffect } from 'react';
import styles from './subcategory.module.css';
import { useHistory, useParams } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';
import { useDispatch, useSelector } from 'react-redux';
import {event, pageview} from 'react-ga';
import { ListSubcategory, Loader, ServerError } from 'src/components';
import {EVENT} from "../../config/analytics";
import {AppDispatch} from "../../store/store";
import {trackPage} from "../../utils/track";
import {fetchGetSubcategory, selectSelectedCategory, selectSubcategories} from "../../store/categorySlice";

const SubcategoryPage = () => {
	useTitle('Categoría - albo');
	const history = useHistory();
	const dispatch = useDispatch<AppDispatch>();
	const categories = useSelector(selectSubcategories);
	const category = useSelector(selectSelectedCategory);
	let { category_uuid } = useParams<{ category_uuid: string }>();

	useEffect(() => {
		const promise = dispatch(fetchGetSubcategory(category_uuid));
		return () => {
			promise.abort();
		}
	}, []);

	useEffect(() => {
		if (category._id === category_uuid) {
			trackPage({page: `/category/${category_uuid}`});
			event({category: EVENT.navigation.category, action: EVENT.navigation.actions.category, label:  category.name})
		}
	}, [category])

	return (
		<>
			<div className={styles.title}>
				<i className="ibon-chevron-left" onClick={() => history.goBack()} />
				<div className={styles.titleIcon}>
					<i className={category.icon} />
					<h3>{category.name}</h3>
				</div>
			</div>
			{categories.status === 'success' && <ListSubcategory subcategories={categories.items} />}
			{categories.status === 'pending' && <Loader height={70} />}
			{categories?.status === 'error' && <ServerError />}
		</>
	);
};

export default SubcategoryPage;
