import styles from '../ticketdetail.module.css';
import { createMarkup, timeWithFormat } from '../../../utils';
import { FileType } from './FileType';

const TicketInfo = ({ ticket }) => {
	return (
		<>
			<div className={`m-y-16 ${styles.customer}`}>
				<div dangerouslySetInnerHTML={createMarkup(ticket.ticket?.content)} />
				<span className={styles.time}>{timeWithFormat(ticket.ticket.cdate)}</span>
			</div>
			<div className={styles.containerImgCustomer}>
				{ticket?.ticket?.filesSigned?.map(file => {
					return <FileType url={file} />;
				})}
			</div>
		</>
	);
};

export default TicketInfo;
