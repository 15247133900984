import React from 'react';
import styles from './thumbnails.module.css';
import { FileType } from '../../pages/TicketDetail/components/FileType';

const Thumbnails = ({ files = [], removeFile }) => {
	return (
		<>
			{files.length > 0 && (
				<ul className="m-b-16">
					{files.map((item, index) => {
						console.log({ item });
						return (
							<div key={index + item.type} className={styles.container}>
								<FileType url={item.file} type={item.type} className={styles.thumbnail} />
								<p>{item.name}</p>
								<i role="remove" className="ibon-close" onClick={() => removeFile(index)} />
							</div>
						);
					})}
				</ul>
			)}
		</>
	);
};

export default Thumbnails;
