// variable from segment
import {pageview} from "react-ga";

declare var analytics;

const validateAnalytics = () => typeof analytics !== 'undefined';

export const trackPage = ({page}: {page: string}): void => {
    // google analytics
    pageview(page)

    // segment
    if (validateAnalytics()) {
        analytics.page();
    }
}

export const trackEvent = () => {

}

export const trackIdentity = ({uuid, name, email}) => {
    if (validateAnalytics()) {
        analytics.identify(uuid, {
            name,
            email
        });
    }
}

