import React, {CSSProperties, useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetArticle, selectArticle } from '../../store/articleSlice';
import styles from './articlepage.module.css';
import useTitle from '../../hooks/useTitle';
import { event } from 'react-ga';
import { HeaderBack, RateArticle} from 'src/components';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css"
import { Carousel } from 'react-responsive-carousel';
import {EVENT} from "../../config/analytics";
import {PageContainer} from "../../components/PageContainer/PageContainer";
import {GuidelineRender} from "../../components/GuidelineRender/GuidelineRender";
import {AppDispatch} from "../../store/store";
import {setScenario} from "../../store/scenarioSlice";
import {selectIsLogged} from "../../store/userSlice";
import {trackPage} from "../../utils/track";

const ArticlePage = () => {
	useTitle('Artículo - albo');
	const { article_uuid } = useParams<{ article_uuid: string }>();
	const { item: article, status: statusArticle } = useSelector(selectArticle);
	const isLogged = useSelector(selectIsLogged)
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();
	const [showContactUs, setShowContactUs] = useState(false);
	const [scenarios, setScenarios] = useState([]);
	// TODO: mvp multimedia
	const indicatorStyles: CSSProperties = {
		background: '#EDF0F4',
		width: 6,
		height: 6,
		display: 'inline-block',
		margin: '0 4px',
		borderRadius: '50%'
	};

	const getConfigurableProps = () => ({
		showArrows: false,
		showStatus: false,
		showIndicators: true,
		infiniteLoop: true,
		showThumbs: false,
		useKeyboardArrows: true,
		autoPlay: true,
		stopOnHover: true,
		swipeable: true,
		dynamicHeight: false,
		emulateTouch: true,
		autoFocus: false,
		interval: 10000,
		transitionTime: 500
	});
	const navigateScenario = async (scenario) => {
		dispatch(setScenario(scenario))
		history.push(`/scenario/${scenario.uuid}`);
	}

	useEffect(() => {
		dispatch(fetchGetArticle(article_uuid));
		trackPage({page: `/article/${article_uuid}`});
	}, []);

	useEffect(() => {
		if (article._id === article_uuid) {
			event({category: EVENT.navigation.category, action: EVENT.navigation.actions.article, label:  article.name})
		}
	}, [article]);
	useEffect(() => {
		setShowContactUs(isLogged && (!article?.scenarios || article?.scenarios?.length === 0));
		setScenarios(article.scenarios??[]);
	}, [article]);

	return (
		<PageContainer
			showLoader={(statusArticle === 'pending')}
			showError={(statusArticle === 'error')}
			showChildren={(statusArticle === 'success')}
		>
			<HeaderBack title={article?.name}/>
			<div>
				{article?.carousel && article?.carousel.length > 0 && (
					<Carousel
						{...getConfigurableProps()}
						renderIndicator={(onClickHandler, isSelected, index, label) => {
							if (isSelected) {
								return (
									<li
										style={{ ...indicatorStyles, background: '#3743AA' }}
										aria-label={`Selected: ${label} ${index + 1}`}
										title={`Selected: ${label} ${index + 1}`}
									/>
								);
							}
							return (
								<li
									style={indicatorStyles}
									onClick={onClickHandler}
									onKeyDown={onClickHandler}
									value={index}
									key={index}
									role="button"
									tabIndex={0}
									title={`${label} ${index + 1}`}
									aria-label={`${label} ${index + 1}`}
								/>
							);
						}}
					>
						{article?.carousel.map(file => (
							<div key={file.url+file.order}>
								<img src={`${process.env.PUBLIC_URL}/assets/article/${article?.uuid}/${file.url}`} />
							</div>
						))}
					</Carousel>
				) }
				<GuidelineRender html={article.article}>
					<>
						{isLogged && scenarios.map(scenario => {
							return(
								<button key={scenario.uuid} onClick={() => navigateScenario(scenario)} className={styles.scenario}>
									<h2 className="p-y-16 text-left">{scenario.title}</h2>
									<i className="ibon-chevron-right" />
								</button>
							)
						})}
					</>
				</GuidelineRender>
				{isLogged && <RateArticle article={article} />}
				{
					showContactUs && (
						<div className={styles.button}>
							<button className={`btn-primary bottom`} onClick={() => history.push('/ticket')}>
								Contáctanos
							</button>
						</div>
					)
				}
			</div>
		</PageContainer>
	);
};

export default ArticlePage;
