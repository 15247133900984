import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import SearchPage from './pages/search/SearchPage';
import { AnimatePresence } from 'framer-motion';
import ArticlePage from './pages/article/ArticlePage';
import TicketPage from './pages/ticket/TicketPage';
import { useDispatch, useSelector, useStore } from 'react-redux';
import TicketSuccessPage from './pages/success/TicketSuccessPage';
import TicketErrorPage from './pages/error/TicketErrorPage';
import HomePage from './pages/home/HomePage';
import SubcategoryPage from './pages/subcategory/SubcategoryPage';
import ReactGA from 'react-ga';
import TicketsPage from './pages/tickets/TicketsPage';
import TicketDetailPage from './pages/TicketDetail/TicketDetailPage';
import { getQueryParamsFromUrl } from './utils';
import { addUser, fetchToken, selectAuth } from './store/userSlice';
import Loader from './components/Loader/Loader';
import { ScrollToTop } from './components';
import { ScenarioPage } from './pages/scenario/ScenarioPage';
import { trackIdentity } from './utils/track';
import SubCategorySlugPage from './pages/subcategorySlug/subcategorySlugPage';
import { AppDispatch } from './store/store';

const AppRoutes = () => {
	const store = useStore();
	const dispatch = useDispatch<AppDispatch>();
	const auth = useSelector(selectAuth);
	const [loadingData, setLoadingData] = useState(true);
	store.subscribe(() => {
		sessionStorage.setItem('store', JSON.stringify(store.getState()));
	});

	const setIdAnalytics = () => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_UA, { testMode: process.env.NODE_ENV === 'test' });
	};

	const setToken = async () => {
		const customerData = getQueryParamsFromUrl();
		const { uuid, tokenGcm, email, name, secondLastName, lastName } = customerData;
		if (uuid && tokenGcm && email && name && lastName) {
			await dispatch(addUser(customerData));
			await dispatch(fetchToken({ uuid, tokenGcm, email }));
			trackIdentity({ email, uuid, name: `${name} ${secondLastName ?? ''} ${lastName}` });
		}
		setLoadingData(false);
	};
	/*

	useEffect(() => {
		setIdAnalytics();
	}, []);
*/

	useEffect(() => {
		setToken();
	}, []);

	return (
		<Router>
			<ScrollToTop />
			<Route
				render={({ location }) => {
					return (
						<AnimatePresence initial={false}>
							<Switch location={location} key={location.pathname}>
								<Route exact path="/">
									{loadingData && <Loader />}
									{!loadingData && (auth?.token ? <HomePage /> : <Redirect to="/error" />)}
								</Route>
								<Route exact path="/tickets">
									<TicketsPage />
								</Route>
								<Route path="/ticket/:ticket_uuid">
									<TicketDetailPage />
								</Route>
								<Route path="/search">
									<SearchPage />
								</Route>
								<Route path="/article/:article_uuid">
									<ArticlePage />
								</Route>
								<Route path="/ticket">
									<TicketPage />
								</Route>
								<Route path="/ticket-success">
									<TicketSuccessPage />
								</Route>
								<Route path="/ticket-error">
									<TicketErrorPage />
								</Route>
								<Route exact path="/category/direct/:category_slug">
									<SubCategorySlugPage />
								</Route>
								<Route exact path="/category/:category_uuid">
									<SubcategoryPage />
								</Route>
								<Route exact path="/scenario/:scenarioUuid">
									<ScenarioPage />
								</Route>
								<Route exact path="/error">
									<TicketErrorPage />
								</Route>
							</Switch>
						</AnimatePresence>
					);
				}}
			/>
		</Router>
	);
};

export default AppRoutes;
