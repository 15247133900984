import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { selectUser } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import useTitle from '../../hooks/useTitle';
import { fetchTickets, selectTickets } from '../../store/ticketSlice';
import styles from './home.module.css';
import { motion } from 'framer-motion';
import { Variants } from 'framer-motion/types/types';
import { event } from 'react-ga';
import { Header, ListCard, Loader, Search, ServerError } from 'src/components';
import {fetchGetTaxonomy, selectTaxonomy} from "../../store/categorySlice";
import {trackPage} from "../../utils/track";

const variantButton: Variants = {
	exit: {
		position: 'absolute'
	}
};
const variantContainer: Variants = {
	exit: {
		position: 'absolute',
		top: 0,
		opacity: 0,
		transition: { duration: 0.6 }
	}
};

const HomePage = () => {
	useTitle('Help Center - albo');
	const history = useHistory();
	const dispatch = useDispatch();
	const customer = useSelector(selectUser);
	const taxonomy = useSelector(selectTaxonomy);
	const tickets = useSelector(selectTickets);

	useEffect(() => {
		const getData = () => {
			if (customer.ticket_uuid) {
				history.replace(`/ticket/${customer.ticket_uuid}`);
				return;
			}
			if (taxonomy?.status === 'initial' || taxonomy?.status === 'error') {
				dispatch(fetchGetTaxonomy());
			}
			if (tickets?.status === 'initial' || tickets?.status === 'error') {
				dispatch(fetchTickets());
			}
		};
		getData();
		if (history.location.search !== '') {
			history.replace('/');
		}
		trackPage({ page: '/' });
	}, []);

	return (
		<>
			<motion.div
				className={`bg-snow ${tickets?.status === 'success' ? styles.containerWithButton : ''}`}
				exit="exit"
				variants={variantContainer}
			>
				<Header />
				<Link to={{ pathname: '/search' }} style={{ textDecoration: 'none' }}>
					<Search />
				</Link>
				{taxonomy?.status === 'success' && (
					<>
						<div className={styles.buttonPanic}>
							<button onClick={() => {
								event({category: 'home', action: 'click', label: 'botón de pánico'})
								history.push('/article/04ccc8de-027f-4080-984a-4c9437f6f4fd');
							}}>
								Robo o extravío de tarjeta
							</button>
						</div>
						<ListCard items={taxonomy.items} />
						{
							tickets?.meta?.total > 0 && (
								<motion.div className={`bg-snow fixed-bottom m-16`} variants={variantButton}>
									<button className={`btn-primary`} onClick={() => history.push('/tickets')}>
										Mis tickets
									</button>
								</motion.div>
							)
						}
					</>
				)}
			</motion.div>
			{taxonomy?.status === 'error' && <ServerError />}
			{taxonomy?.status === 'pending' && <Loader height={68} />}
		</>
	);
};

export default HomePage;
